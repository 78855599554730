// 获取设备申请记录
const getApplyRecordsUrl = `/gateway/hc-health/manageapi/safetyEquipment/getApplyRecords`;
// 获取详情
const getDetailsByIdUrl = `/gateway/hc-health/manageapi/safetyEquipment/getDetailsById`;
// 审核
const auditUrl = `/gateway/hc-health/manageapi/safetyEquipment/audit`;
// 删除设备申请记录
const deleteRecords = `/gateway/hc-health/manageapi/safetyEquipment/deleteRecords`;
// 获取设备号
const equipmentNoListUrl = `/gateway/hc-device/iot/device/list`;
// 获取剩余可申请设备数量
const getResidueSumsUrl = `/gateway/hc-health/miniapi/safetyEquipment/getResidueSums`;
export {
  equipmentNoListUrl,
  getApplyRecordsUrl,
  getDetailsByIdUrl,
  auditUrl,
  deleteRecords,
  getResidueSumsUrl,
};
