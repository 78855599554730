import { mapHelper } from "@/utils/common";
const status = [
  {
    value: null,
    label: "全部",
  },
  {
    value: 0,
    label: "未安装",
  },
  {
    value: 1,
    label: "已安装",
  },
  {
    value: 2,
    label: "已关闭",
  },
];
const { map: statusMap, setOps: statusOps } = mapHelper.setMap(status);
export { status, statusOps, statusMap };
