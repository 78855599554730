<template>
  <div class="page">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot> </template>
      <template #searchSlot>
        <v-input
          label="长者姓名"
          placeholder="请输入长者姓名"
          v-model="searchParam.elderName"
        ></v-input>
        <v-input
          label="长者电话"
          placeholder="请输入长者电话好嘛"
          v-model="searchParam.mobileNum"
        ></v-input>
        <v-select
          clearable
          :options="statusList"
          v-model="searchParam.status"
          @change="$refs.list.search()"
          label="是否安装"
        />
        <v-input
          label="申请人姓名"
          placeholder="请输入申请人姓名"
          v-model="searchParam.userName"
        ></v-input>
        <v-input
          label="申请人电话"
          placeholder="请输入申请人电话号码"
          v-model="searchParam.applyMobiles"
        ></v-input>
        <div class="sumBox">
          <span>设备总数：{{ sumObj.totalQuantity || 0 }}</span>
          <span>剩余设备数：{{ sumObj.residueQuantity || 0 }}</span>
        </div>
      </template>
      <template #operateSlot="scope">
        <v-button
          v-if="scope.row.status == 1"
          text="编辑"
          type="text"
          @click="toDetail(scope.row, '编辑', false)"
        />
        <v-button
          v-if="scope.row.status == 2"
          text="查看"
          type="text"
          @click="toDetail(scope.row, '查看', true)"
        />
        <v-button
          v-if="scope.row.status == 0"
          text="审核"
          type="text"
          @click="toDetail(scope.row, '审核', false)"
        />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
      </template>
    </v-list>
    <!-- 编辑/审核弹框 -->
    <v-dialog
      :title="dialogTitle"
      v-model="dialogFormVisible"
      width="50%"
      sureTxt="保存"
      @confirm="submit"
      :showConfirmButton="this.form.status != 2 || this.checkFlag == false"
      :oStyle="
        this.form.status != 2 || this.checkFlag == false
          ? 'justify-content: space-between;'
          : 'justify-content: center;'
      "
    >
      <el-form label-position="right" label-width="100px">
        <el-form-item label="安装设备">
          <div
            class="box"
            v-for="(item, index) in form.detailsList"
            :key="index"
          >
            <v-input
              disabled
              clearable
              class="filter-item"
              v-model="item.applyEquipment"
            ></v-input>
            <v-input
              v-if="item.equipmentNumber != ''"
              disabled
              clearable
              class="filter-item equipmentNumberInput"
              v-model="item.equipmentNumber"
            ></v-input>
            <v-button
              class="addBtn"
              v-if="item.equipmentNumber == '' && dialogTitle != '查看'"
              text="添加设备号"
              @click="addEquipmentNumber(item, index)"
            />
            <v-button
              class="deleteBtn"
              v-if="item.equipmentNumber != ''"
              text="删除"
              @click="deleteEquipmentNumber(item, index)"
            />
          </div>
        </el-form-item>
        <el-form-item label="长者姓名">
          <v-input
            disabled
            clearable
            class="filter-item"
            v-model="form.elderName"
          ></v-input>
        </el-form-item>
        <el-form-item label="长者年龄">
          <v-input
            disabled
            clearable
            class="filter-item"
            v-model="form.elderAge"
          ></v-input>
        </el-form-item>
        <el-form-item label="长者身份证">
          <v-input
            disabled
            clearable
            class="filter-item"
            v-model="form.elderIdNumber"
          ></v-input>
        </el-form-item>
        <el-form-item label="长者电话">
          <v-input
            disabled
            clearable
            class="filter-item"
            v-model="form.mobileNum"
          ></v-input>
        </el-form-item>
        <el-form-item label="紧急联系人">
          <v-input
            disabled
            clearable
            class="filter-item"
            v-model="form.emergencyContactName"
          ></v-input>
        </el-form-item>
        <el-form-item label="联系人电话">
          <v-input
            disabled
            clearable
            class="filter-item"
            v-model="form.emergencyContactMobile"
          ></v-input>
        </el-form-item>
        <el-form-item label="备选联系人">
          <v-input
            disabled
            clearable
            class="filter-item"
            v-model="form.standbyContactName"
          ></v-input>
        </el-form-item>
        <el-form-item label="备选人电话">
          <v-input
            disabled
            clearable
            class="filter-item"
            v-model="form.standbyContactMobile"
          ></v-input>
        </el-form-item>
        <el-form-item label="提交时间">
          <v-input
            disabled
            clearable
            class="filter-item"
            v-model="form.insertTime"
          ></v-input>
        </el-form-item>
        <el-form-item label="是否安装">
          <v-select
            :disabled="this.dialogTitle == '查看'"
            :options="handleStatus"
            v-model="form.status"
          />
        </el-form-item>
        <el-form-item label="备注">
          <v-input
            clearable
            :disabled="this.dialogTitle == '查看'"
            class="filter-item"
            v-model="form.remark"
            type="textarea"
            :rows="3"
          ></v-input>
        </el-form-item>
      </el-form>
    </v-dialog>
    <!-- 添加设备号弹框 -->
    <v-dialog
      title="选择设备号"
      v-model="dialogFormVisible_add"
      width="50%"
      @confirm="submit_add"
    >
      <el-form label-position="right" label-width="100px">
        <el-form-item label="设备号">
          <v-select :options="equipmentList" v-model="equipment" />
        </el-form-item>
      </el-form>
    </v-dialog>
  </div>
</template>

<script>
import {
  getApplyRecordsUrl,
  getDetailsByIdUrl,
  auditUrl,
  deleteRecords,
  equipmentNoListUrl,
  getResidueSumsUrl,
} from "./api";
import { status, statusOps, statusMap } from "./map";
export default {
  name: "equipmentApply",
  data() {
    return {
      sumObj: {},
      checkFlag: false,
      dialogTitle: "",
      dialogFormVisible: false,
      dialogFormVisible_add: false,
      equipmentList: [],
      equipment: null,
      tableUrl: getApplyRecordsUrl,
      statusList: status,
      searchParam: {
        elderName: "",
        mobileNum: "",
        status: null,
        userName: "",
        applyMobiles: "",
      },
      headers: [
        {
          prop: "detailsList",
          label: "安装设备",
          formatter: (row, prop) => {
            let nameList = [];
            row.detailsList.forEach((item) => {
              nameList.push(item.applyEquipment);
            });
            return nameList.join(",");
          },
        },
        {
          prop: "elderName",
          label: "长者姓名",
        },
        {
          prop: "elderAge",
          label: "长者年龄",
        },
        {
          prop: "elderIdNumber",
          label: "长者身份证号",
        },
        {
          prop: "mobileNum",
          label: "长者联系电话",
        },
        {
          prop: "userName",
          label: "申请人姓名",
        },
        {
          prop: "applyMobiles",
          label: "申请人手机号",
        },
        {
          prop: "insertTime",
          label: "提交时间",
        },
        {
          prop: "status",
          label: "是否安装",
          align: "center",
          formatter: (row, prop) => {
            return statusMap[row.status];
          },
        },
      ],
      form: {
        detailsList: null,
        id: null,
        elderAge: null,
        elderName: null,
        elderIdNumber: null,
        mobileNum: null,
        emergencyContactName: null,
        emergencyContactMobile: null,
        standbyContactName: null,
        standbyContactMobile: null,
        insertTime: null,
        status: null,
        remark: null,
        eIndex: null,
      },
    };
  },
  computed: {
    handleStatus() {
      let arr = JSON.parse(JSON.stringify(status));
      arr.splice(0, 1);
      return arr;
    },
  },
  created() {
    this.getSumObj();
  },
  methods: {
    async getSumObj() {
      let params = {
        tenantId: this.$store.state.app.userInfo.tenantId,
      };
      let res = await this.$axios.get(`${getResidueSumsUrl}`, {
        params: params,
      });
      if (res.code === 200) {
        this.sumObj = res.data;
      }
    },
    toDetail(item, typeName, flag) {
      this.checkFlag = flag;
      this.dialogTitle = typeName;
      //编辑/审核/查看按钮
      this.getDetailData(item.id);
    },
    addEquipmentNumber(item, index) {
      //添加
      this.eIndex = index;
      this.dialogFormVisible_add = true;
      let params = {
        deviceName: item.applyEquipment,
        isBinded: 0,
        tenantId: this.$store.state.app.userInfo.tenantId,
      };
      this.$axios
        .get(`${equipmentNoListUrl}`, { params: params })
        .then((res) => {
          if (res.code === 200) {
            console.log(res.data.records);
            res.data.records.forEach((item) => {
              item.value = item.deviceNum;
              item.label = item.deviceNum;
            });
            this.equipmentList = res.data.records;
          }
        });
    },
    deleteEquipmentNumber(item, index) {
      //删除
      item.equipmentNumber = "";
    },
    submit_add() {
      this.form.detailsList[this.eIndex].equipmentNumber = this.equipment;
      this.dialogFormVisible_add = false;
      this.equipment = null;
      console.log(this.form.detailsList);
    },
    submit() {
      let list = [];
      this.form.detailsList.forEach((item) => {
        list.push(item.equipmentNumber);
      });
      // console.log(list.length);
      // console.log(list);
      // console.log(this.form.detailsList.length);
      // console.log(this.form.detailsList);
      if (this.form.status == 1 && list.indexOf("") != -1) {
        this.$message.error("请为所有设备添加设备号！！！");
        return;
      } else if (
        this.form.status == 2 &&
        list.join(",").length != this.form.detailsList.length - 1
      ) {
        this.$message.error("请将所有设备号删除进行关闭！！！");
        return;
      } else if (this.form.status == 2 && this.form.remark == "") {
        this.$message.error("关闭备注不能为空！！！");
        return;
      }
      // v-if="this.form.status != 2 || this.checkFlag == false"
      let params = {
        id: this.form.id,
        userId: this.form.userId,
        status: this.form.status,
        infoList: this.form.detailsList,
        remark: this.form.remark,
      };
      console.log(this.form.detailsList);
      this.$axios.post(`${auditUrl}`, params).then((res) => {
        if (res.code === 200) {
          this.dialogFormVisible = false;
          this.$message.success("操作成功");
          this.$refs.list.search();
        }
      });
    },
    getDetailData(id) {
      let params = {
        id: id,
      };
      this.$axios
        .get(`${getDetailsByIdUrl}`, { params: params })
        .then((res) => {
          if (res.code === 200) {
            this.form = res.data;
            this.dialogFormVisible = true;
          }
        });
    },
    toDelete(item) {
      //删除按钮
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          id: item.id,
        };
        this.$axios
          .post(`${deleteRecords}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("删除成功");
              this.$refs.list.search();
            } else {
            }
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  box-sizing: border-box;
  height: 100%;
  .sumBox {
    height: 50px;
    line-height: 50px;
    span {
      margin-right: 20px;
    }
  }
  .box {
    display: flex;
    margin-bottom: 22px;
    .equipmentNumberInput,
    .addBtn,
    .deleteBtn {
      margin-left: 20px;
    }
  }
  .box:nth-last-child(1) {
    margin-bottom: 0;
  }
}
</style>
